import generatorsData from "../../../generators/generators.json";

interface Generator {
  id: string;
  altId: string[];
  cfgU: number;
  cfgM: number;
  cfgL: number;
  styleName: { name: string; value: string }[];
  styleImage: string;
}

const generatorsDataTyped: Generator[][] = generatorsData as Generator[][];

export const generators0: Generator[] = generatorsDataTyped[0];
export const generators1: Generator[] = generatorsDataTyped[1];
export const generators2: Generator[] = generatorsDataTyped[2];
export const generators3: Generator[] = generatorsDataTyped[3];
export const generators4: Generator[] = generatorsDataTyped[4];
export const generators5: Generator[] = generatorsDataTyped[5];
export const generators6: Generator[] = generatorsDataTyped[6];
export const generators7: Generator[] = generatorsDataTyped[7];
export const generators8: Generator[] = generatorsDataTyped[8];
export const generators9: Generator[] = generatorsDataTyped[9];
export const generators10: Generator[] = generatorsDataTyped[10];

export const getGenerators = () => {
  switch (process.env.NEXT_PUBLIC_BASE_URL) {
    case "https://www.8porn.ai":
      return generators0;
    case "https://www.createporn.com":
      return generators0;
    case "https://www.createaigayporn.com":
      return generators1;
    case "https://www.createhentai.com":
      return generators2;
    case "https://www.createaifurry.com":
      return generators3;
    case "https://www.createaiasian.com":
      return generators4;
    case "https://www.createaishemale.com":
      return generators5;
    case "https://www.createaiebony.com":
      return generators6;
    case "https://www.createaibbw.com":
      return generators7;
    case "https://www.createailatina.com":
      return generators8;
    case "https://www.createaiindian.com":
      return generators9;
    case "https://www.createaimilf.com":
      return generators10;
    default:
      return generators0; // Default case, can also be some default generators
  }
};

export const generators = getGenerators();

export const findStyleName = (locale: string, style: string) => {
  let foundGenerator = null;
  for (let gen of generators) {
    if (gen.id == style) {
      foundGenerator = gen;
      break;
    }
  }

  if (!foundGenerator) {
    return null;
  }

  for (let name of foundGenerator.styleName) {
    if (name.name == locale) {
      return name.value;
    }
  }
  return null;
};

export function findStyleNameByIdAndLocale({
  id,
  locale,
}: {
  id: string | string[];
  locale: string;
}) {
  const generators = getGenerators();

  let foundGenerator = null;

  // Handle both single string and array of strings for id
  if (Array.isArray(id)) {
    // Find the first generator where any id in the array matches
    foundGenerator = generators.find((gen) =>
      id.some((singleId) => gen.id === singleId || (gen.altId && gen.altId.includes(singleId))),
    );
  } else {
    // Find the generator with the given id or altId
    foundGenerator = generators.find(
      (gen) => gen.id === id || (gen.altId && gen.altId.includes(id)),
    );
  }

  if (!foundGenerator) {
    return null;
  }

  // Find the styleName with the given locale within the generator
  const style = foundGenerator.styleName.find((style) => style.name === locale);
  return style ? style.value : null;
}

// TODO: make this a map more efficient for lookup, generators can be static
export function findIdByAltId({ altId }: { altId: string }) {
  // console.log("Searching for altId:", altId);

  const generators = getGenerators();
  // console.log("Generators:", generators);

  const generator = generators.find((gen) => gen.altId && gen.altId.includes(altId));
  if (!generator) {
    // console.log('Generator not found for altId:', altId);
    return null;
  }

  // console.log('Found primary ID:', generator.id);
  return generator.id;
}

export const indexToLocaleKey: any = {
  0: "en-US",
  1: "ja-JP",
  2: "ko-KR",
  3: "zh-CN",
  4: "zh-TW",
  5: "es-MX",
  6: "de-DE",
  7: "fr-CA",
  8: "ru-RU",
  9: "pt-BR",
};
export const gifReady = () => {
  switch (process.env.NEXT_PUBLIC_BASE_URL) {
    case "https://www.8porn.ai":
      return true;
    case "https://www.createporn.com":
      return true;
    case "https://www.createaigayporn.com":
      return false;
    case "https://www.createhentai.com":
      return false;
    case "https://www.createaifurry.com":
      return false;
    case "https://www.createaiasian.com":
      return true;
    case "https://www.createaishemale.com":
      return false;
    case "https://www.createaiebony.com":
      return true;
    case "https://www.createaibbw.com":
      return true;
    case "https://www.createailatina.com":
      return true;
    case "https://www.createaiindian.com":
      return true;
    case "https://www.createaimilf.com":
      return true;
    default:
      return false;
  }
};

export const gifGenerator = () => {
  switch (process.env.NEXT_PUBLIC_BASE_URL) {
    case "https://www.8porn.ai":
      return "6795cbf25b8a7b9c59b42e77";
    case "https://www.createporn.com":
      return "6795cbf25b8a7b9c59b42e77";
    case "https://www.createaigayporn.com":
      return "";
    case "https://www.createhentai.com":
      return "";
    case "https://www.createaifurry.com":
      return "";
    case "https://www.createaiasian.com":
      return "67af5ffa891d212e31ac1719";
    case "https://www.createaishemale.com":
      return "";
    case "https://www.createaiebony.com":
      return "67af6533891d212e31ac171b";
    case "https://www.createaibbw.com":
      return "67af6b8a891d212e31ac171d";
    case "https://www.createailatina.com":
      return "67af72aa891d212e31ac171f";
    case "https://www.createaiindian.com":
      return "67af75e0891d212e31ac1721";
    case "https://www.createaimilf.com":
      return "67af7c60891d212e31ac1725";
    default:
      return "";
  }
};
